import React from "react";
import { graphql } from "gatsby";

import DefaultLayout from "../layouts/default";
import NewsContent from "../components/NewsContent";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ThreeQuartersGrid from "../components/ThreeQuartersGrid";
import BackLink from "../components/BackLink";

interface News34QueryProps {
  data: {
    cms: {
      entry: {
        title: string;
        inhalt: string;
        inhalt2: string;
        gridTitel: string;
        postDate: string;
        matrixInhalt: [
          {
            inhalt: string;
            id: string;
          }
        ];
      };
    };
  };
}

export const News34Query = graphql`
  query ($uri: String!) {
    cms {
      entry(uri: [$uri]) {
        title
        ... on CMS_news_news1434_Entry {
          inhalt
          inhalt2
          gridTitel
          postDate
          matrixInhalt {
            ... on CMS_matrixInhalt_links_BlockType {
              inhalt
              id
            }
            ... on CMS_matrixInhalt_rechts_BlockType {
              inhalt
              id
            }
          }
        }
      }
    }
  }
`;

const News34: React.FC<News34QueryProps> = ({ data }) => {
  const entry = data.cms.entry;

  const date = new Date(entry.postDate);
  const dateOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  return (
    <DefaultLayout title={entry.title + " – News"}>
      <Header />
      <div
        className="newsContent wrapper has-padding-top has-padding-bottom"
        style={{ maxWidth: 900 }}
      >
        <p className="is-color-grey">
          {date.toLocaleDateString("de-CH", dateOptions)}
        </p>
        <h1>{entry.title}</h1>
        <NewsContent content={entry.inhalt} />
        <h3>{entry.gridTitel}</h3>
        <ThreeQuartersGrid>
          {entry.matrixInhalt.map((item) => (
            <NewsContent content={item.inhalt} key={item.id} />
          ))}
        </ThreeQuartersGrid>
        <NewsContent content={entry.inhalt2} />
        <BackLink />
      </div>
      <Footer breadcrumb={entry.title} />
    </DefaultLayout>
  );
};

export default News34;
