import React from "react";

interface NewsContentProps {
  content: "string";
}

const NewsContent: React.FC<NewsContentProps> = ({ content }) => (
  <div dangerouslySetInnerHTML={{ __html: content }} />
);

export default NewsContent;
