import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

const ButtonLink = styled.a``;

const BackLink = () => {
  return (
    <div className="text-centered has-padding-bottom">
      <ButtonLink className="btn orange" onClick={() => navigate(-1)}>
        &nbsp;&nbsp;zurück&nbsp;&nbsp;
      </ButtonLink>
    </div>
  );
};

export default BackLink;
