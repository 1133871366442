import React from "react";
import styled from "styled-components";

import { spacers, breakpoints } from "../styles/variables";

const GridDiv = styled.div`
  @media screen AND (min-width: ${breakpoints.pageWidthTabletHor}px) {
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: ${spacers.spacerMobile}px;
  }

  @media screen AND (min-width: ${breakpoints.pageWidthPC}px) {
    column-gap: ${spacers.spacerPC}px;
  }
`;

const ThreeQuartersGrid: React.FC = ({ children }) => (
  <GridDiv>{children}</GridDiv>
);

export default ThreeQuartersGrid;
